// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Deserializer, Serializer } from '@aptos-labs/ts-sdk';
import { DappInfo } from '../../shared';
import {
  deserializeWalletRequestWithArgs,
  SerializedWalletRequest,
  serializeWalletRequestWithArgs,
  WalletRequest,
} from '../../WalletRequest';

export interface ConnectRequest extends WalletRequest<ConnectRequest.RequestName, ConnectRequest.SupportedVersions> {
  args: ConnectRequest.Args;
}

export namespace ConnectRequest {
  export const name = 'connect' as const;
  export type RequestName = typeof name;

  export const supportedVersions = [1, 2, 3] as const;
  export type SupportedVersions = (typeof supportedVersions)[number];
  export const currentVersion = 3 as const;
  export type CurrentVersion = typeof currentVersion;

  // region Args

  export type Args = {
    dappEd25519PublicKeyB64?: string;
    dappId?: string;
    // Whether to display paired accounts under the specified wallet first
    preferredWalletName?: string;
  };

  export function serializeArgs(serializer: Serializer, value: Args) {
    serializer.serializeOptionStr(value.dappId);
    serializer.serializeOptionStr(value.dappEd25519PublicKeyB64);
    if (value.preferredWalletName) {
      serializer.serializeOptionStr(value.preferredWalletName);
    }
  }

  export function deserializeArgs(deserializer: Deserializer, version: SupportedVersions): Args {
    if (version === 1) {
      return {};
    }

    const dappId = deserializer.deserializeOptionStr();
    const dappEd25519PublicKeyB64 = deserializer.deserializeOptionStr();
    const preferredWalletName = version >= 3 ? deserializer.deserializeOptionStr() : undefined;
    return { dappEd25519PublicKeyB64, dappId, preferredWalletName };
  }

  // endregion

  export function serialize(
    dappInfo: DappInfo,
    args: ConnectRequest.Args = {},
  ): SerializedWalletRequest<RequestName, 2 | 3> {
    const version = args.preferredWalletName ? 3 : 2;
    return serializeWalletRequestWithArgs({ args, dappInfo, name, version }, serializeArgs);
  }

  export function deserialize(request: SerializedWalletRequest<RequestName, SupportedVersions>): ConnectRequest {
    return deserializeWalletRequestWithArgs(request, (d) => deserializeArgs(d, request.version));
  }

  export function isSerialized(
    request: SerializedWalletRequest,
  ): request is SerializedWalletRequest<RequestName, SupportedVersions> {
    return request.name === name && supportedVersions.includes(request.version as SupportedVersions);
  }
}
